import {useContext, useState} from "react";
import Button from "@mui/material/Button";
import {AuthContext} from "../contexts/AuthContext";

const DialogButton = function (
  {sx = {}, color = 'primary', variant = 'contained', label, form: Form, permissions, initialValues}
) {
  const {hasPermission} = useContext(AuthContext)
  const [open, setOpen] = useState(false);

  if (Array.isArray(permissions) && !hasPermission({permissions})) {
    return null;
  }

  return (
    <>
      <Button
        variant={variant}
        color={color}
        sx={{textTransform: 'none', displayPrint: 'none', ...sx}}
        onClick={() => setOpen(true)}
      >{label}</Button>
      <Form
        open={open}
        handleClose={() => setOpen(false)}
        initialValues={initialValues}
      />
    </>
  )
}

export default DialogButton;
