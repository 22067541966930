import PurchaseApi from "../api/purchase";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import DetailPageHOC from "../components/detail-page-hoc";
import DetailActionButtons from "../components/purchase/DetailActionButtons";
import DetailTable from "../components/purchase/DetailTable";
import StatusHistoryHOC from "../components/purchase/StatusHistoryHOC";
import SupplierTable from "../components/purchase/SupplierTable";
import PurchaseItemTable from "../components/purchase/PurchaseItemTable";
import InvoiceTable from "../components/purchase/InvoiceTable";
import PurchaseNotes from "../components/purchase/PurchaseNotes";

const PurchaseDetail = function () {
    return (
        <DetailPageHOC api={PurchaseApi}>
            <DetailActionButtons/>
            <Grid container spacing={2} marginTop={3}>
                <Grid item md={8} xs={12}>
                    <Stack spacing={2} marginBottom={3}>
                        <DetailTable showCreator/>
                        <InvoiceTable/>
                        <PurchaseItemTable/>
                    </Stack>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Stack spacing={2}>
                        <SupplierTable/>
                        <StatusHistoryHOC/>
                        <PurchaseNotes />
                    </Stack>
                </Grid>
            </Grid>
        </DetailPageHOC>
    )
}

export default PurchaseDetail;
