import Grid from "@mui/material/Grid";
import Logo from '../../renkli_logo.png';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import {transformDateToLocalString} from "../../utils/transform";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {userLabelFormat} from "../../helpers/user/format";
import Link from "@mui/material/Link";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import PriceTextFormat from "../PriceTextFormat";

const HeadTableRow = function ({label, value, color}) {
  return (
    <TableRow>
      <TableCell align="right" style={{border: 'none', width: '220px'}}>
        <Typography variant="body2">{label}</Typography>
      </TableCell>
      <TableCell style={{border: 'none'}}>
        <Typography variant="body2" fontWeight="bold" color={color}>{value}</Typography>
      </TableCell>
    </TableRow>
  )
}


const PrintableDesign = function ({refToPrint}) {
  const {state} = useContext(DetailPageContext);
  const data = state.data

  return (
    <Grid spacing={1} container ref={refToPrint} sx={{py: 5, px: 7}}>
      <Grid item xs={9}>
        <Box sx={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Typography variant="h5" marginLeft={1}>
            Satın Alma Sipariş Formu
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box sx={{textAlign: 'right'}}>
          <img src={Logo} alt={'Kestaş Logo'} height={50}/>
          <Typography variant="subtitle2">
            Kestaş Broş Tığları İml. San. Ve Tic. A.Ş.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} sx={{border: '1px solid rgba(0, 0, 0, 0.5)'}}>
        <Table size="small">
          <HeadTableRow label="Tedarikçi Firma:" value={data.supplier.name}/>
          <HeadTableRow label="Tedarikçi Firma Yetkilisi:" value={data.contactPerson}/>
          <HeadTableRow
            label="Kestaş Satınalma Sipariş No:"
            value={data.companyPurchaseNumber}
            color="red"
          />
          <HeadTableRow
            label="Satınalmayı Gerçekleştiren:"
            value={userLabelFormat({user: data.user, type: 'fullName'})}
          />
          <HeadTableRow
            label="Satınalma Oluşturma Tarihi:"
            value={transformDateToLocalString(data.createdAt)}
          />
        </Table>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{textAlign: 'right'}}>
          {[
            'Ömerli Mah. Mısır Sok. No:8',
            'Arnavutköy / İSTANBUL',
            'VD: BÜYÜKÇEKMECE',
            'VN: 548 001 5087',
            'Tel: 0212 798 28 55',
            'Fax: 0212 798 28 59',
            <Link href="https://www.kestas.com.tr" target="_blank">www.kestas.com.tr</Link>,
          ].map((item, index) => (
            <Typography key={index} variant="body2" fontWeight="light">{item}</Typography>
          ))}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">Sayın Yetkili;</Typography>
        <Typography variant="body2">
          Lütfen aşağıdaki kalemleri teslimat şartlarımıza uygun olarak teslim ediniz.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Sıra No</TableCell>
              <TableCell>Ürün Malzeme Tanımı/Tarifi</TableCell>
              <TableCell>Ebatlar</TableCell>
              <TableCell>Miktar</TableCell>
              <TableCell>Birim Fiyat</TableCell>
              <TableCell>İstenen Teslim Tarihi</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.items.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell style={{border: 'none'}}>
                  <Typography variant="body2">{index + 1}</Typography>
                </TableCell>
                <TableCell style={{border: 'none'}}>
                  <Typography variant="body2">{item.name}</Typography>
                </TableCell>
                <TableCell style={{border: 'none'}}>
                  <Typography variant="body2">{item.description}</Typography>
                </TableCell>
                <TableCell style={{border: 'none'}}>
                  <Typography variant="body2">{item.quantity} {item.quantityUnit}</Typography>
                </TableCell>
                <TableCell style={{border: 'none'}}>
                  <Typography variant="body2">
                    <PriceTextFormat
                      prefix={`${item.currency}/${item.unit}`}
                      value={item.unitPrice}
                    />
                  </Typography>
                </TableCell>
                <TableCell style={{border: 'none'}}>
                  <Typography variant="body2">
                    {
                      data.deadline
                        ? transformDateToLocalString(data.deadline)
                        : '-'
                    }
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{borderTop: '1px solid black', mt: 2, pt: 2}}>
          <Typography variant="body2">
            1. Sipariş 3 gün içerisinde teyit edilmediği takdirde tedarikçi sipariş
            formundaki teslim şartlarını aynen kabul etmiş sayılır.
          </Typography>
          <Typography variant="body2">
            2. Bu sipariş içeriğinin satınalınmasında Kestaş Broş Tığları Genel
            Satınalma Şartnamesi geçerlidir.
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            3. <span style={{color: 'red'}}>Kestaş satınalma sipariş numarası</span> bu sipariş ile ilgili fatura,
            irsaliye, paket etiketi ve diğer tüm resmi yazışma içeriklerinde mutlaka açıkça belirtilmelidir.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2" fontWeight="light">TESLİMAT: <b>{data.deliveryInformation}</b></Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2" fontWeight="light">ÖDEME: <b>{data.paymentInformation}</b></Typography>
      </Grid>
      <Grid item xs={4}>
        <table style={{textAlign: 'right'}}>
          <tbody>
          <tr>
            <td></td>
            <td><Typography variant="subtitle1" fontWeight="bold">Tedarikçi Yetkilisi Onay</Typography></td>
            <td></td>
          </tr>
          <tr>
            <td><Typography variant="body2">İsim:</Typography></td>
            <td></td>
          </tr>
          <tr>
            <td><Typography variant="body2">İmza ve Kaşe:</Typography></td>
            <td></td>
          </tr>
          </tbody>
        </table>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">F.16(01-020119)</Typography>
      </Grid>
    </Grid>
  )
}

export default PrintableDesign;
