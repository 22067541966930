import ListActionButtons from "../components/suppliers/ListActionButtons";
import DataTable from "../components/suppliers/DataTable";
import ListPageHOC from "../components/list-page-hoc";

const Suppliers = function () {
    return (
        <ListPageHOC
            storageKey="suppliers-page"
            sortModel={[{field: "name", sort: "asc"}]}
        >
            <ListActionButtons/>
            <DataTable/>
        </ListPageHOC>
    )
}

export default Suppliers;
