import {useState, useEffect} from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import PriceFieldFormat from "./PriceFieldFormat";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import InputAdornment from "@mui/material/InputAdornment";
import CopyToClipboardButton from "./CopyToClipboardButton";
import {useFormikContext} from "formik";

const SharpeningPriceCalculation = function ({sx = {}, updateFormikState = false}) {
    const formik = useFormikContext();
    const [cuttingEdgeCount, setCuttingEdgeCount] = useState(0);
    const [averageDiameter, setAverageDiameter] = useState(0.0);
    const [price, setPrice] = useState(0.0);
    const [multipleResult, setMultipleResult] = useState([]);

    useEffect(() => {
        setPrice(
            Math.round(
                ((((averageDiameter / 100) + 1) * cuttingEdgeCount) * 0.3257) * 10000
            ) / 10000
        )
    }, [cuttingEdgeCount, averageDiameter]);

    return (
        <Stack spacing={2} sx={sx}>
            <Typography variant={"body2"}>Bileme Fiyat Hesaplama Formülü</Typography>
            <Stack spacing={1}>
                <TextField
                    fullWidth
                    size={"small"}
                    label={"Kesici Diş Sayısı"}
                    inputMode={"numeric"}
                    value={cuttingEdgeCount}
                    onChange={event => {
                        if (updateFormikState) {
                            formik.setFieldValue('cuttingEdgeCount', Number(event.target.value))
                        }
                        setCuttingEdgeCount(Number(event.target.value))
                    }}
                />
                <TextField
                    fullWidth
                    size={"small"}
                    label={"Ortalama Çap Değeri veya b Değeri"}
                    inputMode={"decimal"}
                    InputProps={{
                        inputComponent: PriceFieldFormat
                    }}
                    value={averageDiameter}
                    onChange={event => {
                        if (updateFormikState) {
                            formik.setFieldValue('averageDiameter', Number(event.target.value))
                        }
                        setAverageDiameter(Number(event.target.value))
                    }}
                />
                <Divider/>
                <Stack spacing={1} direction={"row"}>
                    <TextField
                        fullWidth
                        size={"small"}
                        disabled
                        label={"Hesaplama Sonuç (€)"}
                        inputMode={"decimal"}
                        value={price.toFixed(2)}
                        InputProps={{
                            inputComponent: PriceFieldFormat,
                            endAdornment: <InputAdornment position="end">
                                <CopyToClipboardButton
                                    sx={{
                                        px: 1,
                                        opacity: 0.1,
                                        transition: 'opacity ease-out 300ms',
                                        '&:hover': {
                                            opacity: 1
                                        }
                                    }}
                                    copyText={String(price.toFixed(2)).replace('.', ',')}
                                    message={'Hesaplama sonucu kopyalandı'}
                                />
                            </InputAdornment>
                        }}
                    />
                    <Button
                        size={"small"}
                        variant={"outlined"}
                        onClick={() => {
                            setMultipleResult(prevState => [
                                ...prevState,
                                {
                                    cuttingEdgeCount,
                                    averageDiameter,
                                    price,
                                }
                            ])
                        }}
                    >Topla</Button>
                    <Button
                        size={"small"}
                        color={"warning"}
                        onClick={() => {
                            setMultipleResult([]);
                        }}
                    >Sıfırla</Button>
                </Stack>
                <Divider/>
                {
                    multipleResult.length > 0 && (
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Kesici Diş Sayısı</TableCell>
                                    <TableCell>Ortalama Çap</TableCell>
                                    <TableCell>Fiyat</TableCell>
                                </TableRow>
                                {
                                    multipleResult.map(result => {
                                        return (
                                            <TableRow>
                                                <TableCell>{result.cuttingEdgeCount}</TableCell>
                                                <TableCell>{result.averageDiameter}</TableCell>
                                                <TableCell>{result.price.toFixed(2)}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Toplam</TableCell>
                                    <TableCell>
                                        {
                                            multipleResult.reduce(
                                                (sum, {price}) => price + sum, 0
                                            ).toFixed(2)
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    )
                }
            </Stack>
        </Stack>
    )
}

export default SharpeningPriceCalculation;
