import BaseDataTable from "../datatable/ClientDataTable";
import PriceTextFormat from "../PriceTextFormat";

const PAGE_SIZE = 10

function PurchaseItemDataTable({items}) {
  return (
    <BaseDataTable
      sx={{minHeight: 200}}
      rowCount={items.length}
      rows={items.map((item, index) => ({index: index + 1, ...item}))}
      columns={[
        {
          field: 'index',
          filterable: false,
          headerName: 'Sıra No',
          sortable: false,
          width: 70,
        },
        {
          field: 'name',
          filterable: false,
          headerName: 'Ürün Adı/Tanımı/Tarifi',
          sortable: false,
          flex: 1,
        },
        {
          field: 'relationDescription',
          headerName: 'İlgi̇li̇ Kestaş Ürün Kodu/Maki̇ne Kodu/Ne İçi̇n Alındı',
          sortable: false,
          filterable: false,
          flex: 1,
        },
        {
          field: 'description',
          headerName: 'Ebatlar',
          sortable: false,
          filterable: false,
          flex: 1,
        },
        {
          field: 'quantity',
          headerName: 'Miktar',
          filterable: false,
          sortable: false,
          valueFormatter: (params) => {
            const row = params.api.getRow(params.id)
            return `${params.value} ${row.quantityUnit}`
          },
        },
        {
          field: 'unitPrice',
          filterable: false,
          headerName: 'Birim Fiyat',
          sortable: false,
          width: 120,
          renderCell: (params) => <PriceTextFormat
            prefix={`${params.row.currency}/${params.row.unit}`}
            value={params.value}
          />
        },
        {
          field: 'category',
          headerName: 'Maliyet Merkezi',
          filterable: false,
          sortable: false,
        },
      ]}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: PAGE_SIZE,
          },
        }
      }}
      pageSizeOptions={[PAGE_SIZE]}
    />
  )
}

export default PurchaseItemDataTable;
