import {useContext, useMemo, useState} from "react";
import {AuthContext} from "../../contexts/AuthContext";
import {Formik} from "formik";
import format from "date-fns/format";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import TextField from "../forms/TextField";
import DatePicker from "../forms/DatePicker";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import * as yup from "yup";
import {DetailPageContext} from "../detail-page-hoc/context";
import PurchaseApi from "../../api/purchase";
import {detailPageActionTypes} from "../detail-page-hoc/constants";
import PriceFieldFormat from "../PriceFieldFormat";
import {useSnackbar} from "notistack";
import SelectField from "../forms/SelectField";

const AddInvoiceFormDialog = function (
  {
    open,
    handleClose,
    initialValues = {
      purchaseItem: '',
      invoiceNo: '',
      invoiceDate: '',
      totalPrice: '',
      accountNumber: '',
    },
  }
) {
  const {enqueueSnackbar} = useSnackbar();
  const authorization = useContext(AuthContext);
  const {state, dispatch} = useContext(DetailPageContext);
  const [loading, setLoading] = useState(false);
  const items = useMemo(() => {
    const invoiceItemIds = state.data.invoices.map(invoice => invoice.purchaseItem)
    return state.data.items
      .filter(item => !invoiceItemIds.includes(item.id))
      .map(item => ({
        id: item.id,
        label: `${item.name} | ${item.description}`,
      }));
  }, [state.data])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setLoading(true);
        const payload = {...values}
        Object.entries(payload).forEach(([key, value]) => {
          if (value && value instanceof Date) {
            payload[key] = format(new Date(value), 'yyyy-MM-dd');
          }
        });
        PurchaseApi.updateStatus(
          {authorization},
          state.data.id,
          {...payload, process: 'add_invoice'},
        ).then(data => {
          dispatch({type: detailPageActionTypes.UPDATE_DATA, data});
          setLoading(false);
          handleClose();
        }).catch(err => {
          if (err?.response?.status === 400) {
            enqueueSnackbar('Hatalı işlem', {
              variant: "error"
            });
          }
          setLoading(false);
        });
      }}
    >
      {formik => (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
          <DialogTitle>Satın Alma Tamamla</DialogTitle>
          <DialogContent>
            <Stack sx={{py: 1}} spacing={1}>
              <SelectField
                name="purchaseItem"
                label="Ürün"
                values={items}
                nullable={false}
                valueField="id"
                itemLabelField="label"
                size="small"
              />
              <TextField label="Tedarikçi Fatura No" name="invoiceNo" size="small"/>
              <DatePicker
                label="Tedarikçi Fatura Tarihi"
                name="invoiceDate"
                size={'small'}
              />
              <TextField
                name="totalPrice"
                label="Fatura Bedeli (KDV Hariç - TL)"
                inputMode="decimal"
                InputProps={{
                  inputComponent: PriceFieldFormat
                }}
                size="small"
              />
              <TextField label="Hesap No" name="accountNumber" size="small"/>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={loading}
              sx={{textTransform: 'none'}}
              onClick={() => formik.submitForm()}
            >Kaydet</Button>
            <Button
              disabled={loading}
              sx={{textTransform: 'none'}}
              color="error"
              onClick={handleClose}
            >İptal</Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

const validationSchema = yup.object({
  purchaseItem: yup
    .number()
    .required('Zorunlu alan'),
  invoiceNo: yup
    .string()
    .required('Zorunlu alan'),
  invoiceDate: yup
    .date()
    .required('Zorunlu alan'),
  totalPrice: yup
    .number()
    .min(0, 'Birim fiyat negatif olamaz')
    .required('Zorunlu alan'),
  accountNumber: yup.string().required('Zorunlu alan'),
});

export default AddInvoiceFormDialog;