import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";

const SupplierTable = function () {
  const {state} = useContext(DetailPageContext);
  return (
    <Paper sx={{padding: 2}}>
      <Typography variant="subtitle1">
        Tedarikçi Firma
      </Typography>
      <Divider/>
      <Typography
        variant="subtitle2"
        sx={{marginTop: 2}}
      >{state.data.supplier.name}
      </Typography>
      <Typography
        variant="body2"
      >{state.data.supplier.phone}
      </Typography>
      <Typography
        variant="body2"
      >{state.data.supplier.address}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{marginTop: 2}}
      >İletişim kişisi
      </Typography>
      <Divider/>
      <Typography
        variant="body2"
        sx={{marginTop: 2}}
      >{state.data.supplier.contactPerson}
      </Typography>
      <Typography
        variant="body2"
      >{state.data.supplier.contactPersonPhone}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{marginTop: 2}}
      >Firma yetkilisi
      </Typography>
      <Divider/>
      <Typography
        variant="body2"
        sx={{marginTop: 2}}
      >{state.data.contactPerson}
      </Typography>
    </Paper>
  )
}

export default SupplierTable;
