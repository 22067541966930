import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import TextField from "../forms/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {Formik} from "formik";
import * as yup from "yup";
import {useContext, useState} from "react";
import EditIcon from "@mui/icons-material/Edit";
import {GridActionsCellItem} from "@mui/x-data-grid-pro";
import {ListPageContext} from "../list-page-hoc/context";

const SupplierFormDialog = function (
  {
    dataTableAction = false,
    initialValues = {
      name: '',
      address: '',
      phone: '',
      paymentInformation: '',
      contactPerson: '',
      contactPersonPhone: '',
    },
    onSubmit = Promise.resolve(true),
    title,
    label,
    sx = {}
  }
) {
  const {state} = useContext(ListPageContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => !loading && setOpen(false);

  return (
    <>
      {
        dataTableAction
          ? (
            <GridActionsCellItem
              icon={<EditIcon/>}
              onClick={() => setOpen(true)}
              label={label}
            />
          )
          : (
            <Button
              variant={'outlined'}
              sx={{textTransform: 'none', displayPrint: 'none', ...sx}}
              onClick={() => setOpen(true)}
            >{label}</Button>
          )
      }
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          // actions.setSubmitting(false);
          setLoading(true);
          const payload = {...values};
          Object.entries(payload).forEach(([key, value]) => {
            if (!value) delete payload[key]
          });
          onSubmit(payload).finally(() => {
            setLoading(false);
            setOpen(false);
          });
        }}
      >
        {formik => (
          <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              <Stack sx={{py: 1}} spacing={1}>
                <TextField name="name" label="Firma Adı" size="small"/>
                <TextField name="address" label="Adres" size="small"/>
                <TextField name="phone" label="Telefon" size="small" inputMode="numeric"/>
                <TextField name="paymentInformation" label="Ödeme Vadesi" size="small"/>
                <TextField name="contactPerson" label="İrtibat Kişisi" size="small"/>
                <TextField name="contactPersonPhone" label="İrtibat Telefon" size="small" inputMode="numeric"/>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={loading}
                sx={{textTransform: 'none'}}
                onClick={() => formik.submitForm()}
              >Kaydet</Button>
              <Button
                disabled={loading}
                sx={{textTransform: 'none'}}
                color="error"
                onClick={handleClose}
              >İptal</Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </>
  )
}

const validationSchema = yup.object({
  name: yup.string().required('Zorunlu alan'),
  phone: yup.string().nullable(),
  address: yup.string().nullable(),
  paymentInformation: yup.string().nullable(),
  contactPerson: yup.string().nullable(),
  contactPersonPhone: yup.string().nullable(),
});

export default SupplierFormDialog;
