export const categoryTranslate = {
    AGG: {
        label: 'AGG',
        enum: 'AGG'
    },
    BB: {
        label: 'BB',
        enum: 'BB'
    },
    DMR: {
        label: 'DMR',
        enum: 'DMR'
    },
    FB: {
        label: 'FB',
        enum: 'FB'
    },
    PI: {
        label: 'PI',
        enum: 'PI'
    },
    TI: {
        label: 'TI',
        enum: 'TI'
    },
    YNG: {
        label: 'YNG',
        enum: 'YNG'
    },
    YS: {
        label: 'YS',
        enum: 'YS'
    },
}
