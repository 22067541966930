import {useCallback, useContext} from "react";
import BaseDataTable from "../datatable";
import {ListPageContext} from "../list-page-hoc/context";
import {listPageActionTypes} from "../list-page-hoc/constants";
import SupplierApi from '../../api/supplier';
import {useListApi} from "../list-page-hoc/hooks";
import SupplierUpdateDialog from "./SupplierUpdateDialog";

function DataTable() {
  const {state, dispatch} = useContext(ListPageContext);
  const {data: suppliers} = useListApi(SupplierApi);

  const handleSortModelChange = sortModel => {
    dispatch({type: listPageActionTypes.SET_SORT_MODEL, sortModel})
  };

  const handleFilterModelChange = useCallback(filterModel => {
    console.log(filterModel);
  }, []);

  const onPageChange = (page) => {
    dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
    dispatch({
      type: listPageActionTypes.SET_PAGINATION,
      pagination: {
        ...state.pagination,
        page: page,
      }
    })
  }

  const onPageSizeChange = (pageSize) => {
    dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
    dispatch({
      type: listPageActionTypes.SET_PAGINATION,
      pagination: {
        ...state.pagination,
        pageSize,
      }
    })
  }

  return (
    <BaseDataTable
      onSortModelChange={handleSortModelChange}
      onFilterModelChange={handleFilterModelChange}
      sortModel={state.sortModel}
      loading={state.loading}
      rowCount={suppliers.count}
      page={state.pagination.page}
      pageSize={state.pagination.pageSize}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      rows={suppliers.results}
      columns={[
        {
          field: 'name',
          headerName: 'Firma Adı',
          sortable: true,
          filterable: false,
          flex: 1,
        },
        {
          field: 'address',
          headerName: 'Adres',
          sortable: false,
          filterable: false,
        },
        {
          field: 'phone',
          headerName: 'Telefon',
          sortable: false,
          filterable: false,
        },
        {
          field: 'paymentInformation',
          headerName: 'Ödeme Vadesi',
          sortable: false,
          filterable: false,
        },
        {
          field: 'contactPerson',
          headerName: 'İrtibat Kişisi',
          sortable: false,
          filterable: false,
        },
        {
          field: 'contactPersonPhone',
          headerName: 'İrtibat Telefon',
          sortable: false,
          filterable: false,
        },
        {
          field: 'actions',
          type: 'actions',
          pinnable: true,
          getActions: (params) => [
            <SupplierUpdateDialog supplier={params.row}/>
          ]
        }
      ]}
    />
  )
}

export default DataTable;
