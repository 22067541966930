import {useContext} from "react";
import {useSnackbar} from "notistack";
import SupplierApi from '../../api/supplier';
import {ListPageContext} from "../list-page-hoc/context";
import SupplierFormDialog from "./SupplierFormDialog";
import {AuthContext} from "../../contexts/AuthContext";
import {listPageActionTypes} from "../list-page-hoc/constants";
import {hasPermission} from "../../utils/permissions";

const SupplierUpdateDialog = function ({sx, supplier}) {
  const {enqueueSnackbar} = useSnackbar();
  const {dispatch} = useContext(ListPageContext);
  const authorization = useContext(AuthContext);

  const update = (values) => {
    return SupplierApi.update({authorization}, supplier.id, values).then(() => {
      dispatch({type: listPageActionTypes.UPDATE_VERSION})
      return {status: true};
    }).catch(err => {
      if (err?.response?.status === 500) {
        enqueueSnackbar('Sunucuda bir hata oldu', {
          variant: "error"
        });
        return {status: false};
      }
      enqueueSnackbar('Hatalı işlem', {
        variant: "error"
      });
      return {status: false, errors: err.response.data};
    });
  }

  return (
    <SupplierFormDialog
      onSubmit={update}
      title="Tedarikçi Kaydını Düzenle"
      label="Düzenle"
      initialValues={supplier}
      sx={sx}
      dataTableAction={true}
    />
  );
}

export default hasPermission(SupplierUpdateDialog, {permissions: ['change_supplier']});
