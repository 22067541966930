import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import PurchaseItemDataTable from "./PurchaseItemDataTable";


function DataTable() {
  const {state} = useContext(DetailPageContext);
  const items = state.data.items;

  return (
    <Stack spacing={1}>
      <Typography variant="h6">Ürünler</Typography>
      <Divider/>
      <PurchaseItemDataTable items={items} />
    </Stack>
  )
}

export default DataTable;
