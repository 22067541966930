import {useContext} from "react";
import {ListPageContext} from "../list-page-hoc/context";
import {useSnackbar} from "notistack";
import SupplierApi from '../../api/supplier';
import {listPageActionTypes} from "../list-page-hoc/constants";
import SupplierFormDialog from "./SupplierFormDialog";
import {AuthContext} from "../../contexts/AuthContext";
import {hasPermission} from "../../utils/permissions";

const SupplierCreateDialog = function () {
  const {enqueueSnackbar} = useSnackbar();
  const {dispatch} = useContext(ListPageContext);
  const authorization = useContext(AuthContext);

  const create = (values) => {
    return SupplierApi.save({authorization}, values).then(() => {
      dispatch({type: listPageActionTypes.UPDATE_VERSION})
      return {status: true};
    }).catch(err => {
      if (err?.response?.status === 500) {
        enqueueSnackbar('Sunucuda bir hata oldu', {
          variant: "error"
        });
        return {status: false};
      }
      enqueueSnackbar('Hatalı işlem', {
        variant: "error"
      });
      return {status: false, errors: err.response.data};
    });
  }

  return (
    <SupplierFormDialog
      onSubmit={create}
      title="Tedarikçi Oluştur"
      label="Tedarikçi Oluştur"
      sx={{ml: 1}}
    />
  );
}

export default hasPermission(SupplierCreateDialog, {permissions: ['add_supplier']});
