import {useContext} from "react";
import MessageBox from "../message-box";
import PurchaseNoteApi from '../../api/purchase-note';
import {AuthContext} from "../../contexts/AuthContext";
import {transformDateToLocalString} from "../../utils/transform";
import {DetailPageContext} from "../detail-page-hoc/context";
import {userLabelFormat} from "../../helpers/user/format";
import {hasPermission} from "../../utils/permissions";

const PurchaseNotes = function ({sx}) {
    const authorization = useContext(AuthContext);
    const {state} = useContext(DetailPageContext);
    const purchase = state.data.id;

    const getInitialMessages = () => {
        return PurchaseNoteApi.getAll({authorization}, [], {purchase})
    }

    const sendMessage = message => {
        return PurchaseNoteApi.save({authorization}, {purchase, note: message})
    }

    return (
        <MessageBox
            sx={sx}
            sendMessage={sendMessage}
            getInitialMessages={getInitialMessages}
            messageFormatter={({user, note, createdAt}) => {
                return {
                    sender: userLabelFormat({user}),
                    message: note,
                    datetime: transformDateToLocalString(createdAt, false)
                }
            }}
            title={'Notlar Bölümü'}
        />
    )
}

export default hasPermission(PurchaseNotes, {permissions: ['view_purchasenote']});
