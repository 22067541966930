import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import {transformDateToLocalString} from "../../utils/transform";
import StatusChip from "../invoice-order/StatusChip";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import {useNavigate} from "react-router-dom";
import PriceTextFormat from "../PriceTextFormat";
import {hasPermission} from "../../utils/permissions";

const InvoiceTable = function ({tableSize = 'medium'}) {
  const {state: {data: {invoices, items}}} = useContext(DetailPageContext);

  if (!invoices?.length) {
    return null;
  }

  return (
    <TableContainer component={Paper}>
      <Table size={tableSize}>
        <TableHead>
          <TableRow>
            <TableCell>Sıra No</TableCell>
            <TableCell>Ürün</TableCell>
            <TableCell>Tedarikçi Fatura No</TableCell>
            <TableCell>Tedarikçi Fatura Tarihi</TableCell>
            <TableCell>Fatura Bedeli (KDV Hariç - TL)</TableCell>
            <TableCell>Hesap No</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.sort((a,b) => a.purchaseItem - b.purchaseItem).map((invoice, index) => (
            <TableRow>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{items.find(item => item.id === invoice.purchaseItem).name}</TableCell>
              <TableCell>{invoice.invoiceNo}</TableCell>
              <TableCell>{transformDateToLocalString(invoice.invoiceDate)}</TableCell>
              <TableCell>
                <PriceTextFormat
                  prefix="TL"
                  value={invoice.totalPrice}
                />
              </TableCell>
              <TableCell>{invoice.accountNumber}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default InvoiceTable;
