import Box from "@mui/material/Box";
import SupplierCreateDialog from "./SupplierCreateDialog";
import Button from "@mui/material/Button";
import {listPageActionTypes} from "../list-page-hoc/constants";
import ListSearch from "../ListSearch";
import {useContext} from "react";
import {ListPageContext} from "../list-page-hoc/context";

const ListActionButtons = function () {
  const {dispatch} = useContext(ListPageContext);

  return (
    <Box sx={{width: '100%', display: 'flex'}}>
      <Box sx={{flexGrow: 1}}/>
      <Box sx={{display: 'flex'}}>
        <Button
          sx={{textTransform: 'none'}}
          onClick={() => {
            dispatch({type: listPageActionTypes.SET_FILTERS, filters: {}});
            dispatch({type: listPageActionTypes.SET_SEARCH_TEXT, searchText: ''});
            dispatch({type: listPageActionTypes.SET_INPUT_VALUE, inputValues: {}});
          }}
        >
          Temizle
        </Button>
        <ListSearch/>
        <SupplierCreateDialog/>
      </Box>
    </Box>
  )
}

export default ListActionButtons;
