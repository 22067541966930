import {useState, forwardRef, useRef, useMemo, useContext} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {useReactToPrint} from "react-to-print";
import PrintableDesign from "./PrintableDesign";
import {createTheme, ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import {yellow} from "@mui/material/colors";
import {DetailPageContext} from "../detail-page-hoc/context";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PrintDialog = function ({sx}) {
  const [open, setOpen] = useState(false);
  const refToPrint = useRef()
  const {state} = useContext(DetailPageContext);

  const handlePrint = useReactToPrint({
    content: () => refToPrint.current,
    documentTitle: `${state.data.companyPurchaseNumber}.pdf`,
    pageStyle: `
      @page {
          size: auto;
          margin-top: 8mm;
          @bottom-left {
            content: "Sayfa " counter(page);
          }
      }
    `,
  });

  const handleClose = () => setOpen(false);
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "light",
          statusCompleted: {
            main: yellow[500],
            contrastText: '#000000'
          }
        },
      }),
    [],
  );
  return (
    <>
      <Button
        variant="outlined"
        sx={{textTransform: 'none', ...sx}}
        onClick={() => setOpen(true)}
      >Yazdır</Button>
      <MuiThemeProvider theme={theme}>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{position: 'relative'}}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon/>
              </IconButton>
              <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                Satın alma formu yazdırma
              </Typography>
              <Button
                autoFocus
                color="inherit"
                onClick={handlePrint}
                sx={{textTransform: 'none'}}
              >
                Yazdır
              </Button>
            </Toolbar>
          </AppBar>
          <PrintableDesign refToPrint={refToPrint}/>
        </Dialog>
      </MuiThemeProvider>
    </>
  )
}

export default PrintDialog;
